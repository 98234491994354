.firstItem {
  font-weight: bold;
  font-size: 1.2rem;
  cursor: default;
}

.itemsContainer > *:first-child {
  border-top: 0;
}

.listGroup > * {
  cursor: pointer;
}

.edit {
  font-size: 0.9rem;
  display: none;
}

.item:hover .edit {
  display: block;
}

.hide {
  display: none !important;
}
