.modifyModal {
   /* margin-top: 3rem !important; */
}
/* Styles for the estyle sinside of the Modal body */
.centerStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modifyBottomModal {
  margin-bottom: 1rem;
}
.photoContainer {
  min-height: 19.8rem;
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  padding: 1.4rem;
  margin: 1rem;
  width: 22%;
}

.photo {
  display: block;
  height: 14.5rem;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.optionsContainer button,
.optionsContainer button:active,
.optionsContainer button:target {
  display: block;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0.5rem;
  color: var(--dark);
}

.optionsContainer {
  display: flex;
  justify-content: right;
justify-content: flex-end;
  align-items: center;
  margin-top: 0.4rem;
  min-height: 3.5rem;
}

.star svg {
  width: 1.4rem;
  height: 1.4rem;
}

.x svg {
  width: 2rem;
  height: 2rem;
  margin-top: 0.29rem;
}

.spinner {
  padding: 0.9rem;
}

.spinner span {
  width: 1.4rem;
  height: 1.4rem;
}

.active.photoContainer {
  background-color: var(--primary);
}

.active svg,
.active span {
  color: #ffffff;
}

.active button:disabled svg {
  color: rgba(255, 255, 255, 0.479);
}

.disabled {
  opacity: 0.5;
}

.selectIcon {
  width: 1.6rem;
  height: 1.6rem;
}

.cropButton svg {
  width: 1.2rem;
  height: 1.2rem;
}

@media screen and (max-width: 90em) {
  .photoContainer {
    width: 30%;
  }
}

@media screen and (max-width: 70em) {
  .photoContainer {
    width: 45%;
  }
}

@media screen and (max-width: 40em) {
  .photoContainer {
    width: 100%;
  }
}
