.modifyModal {
  /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}

.prestashopId {
  display: block;
  margin-bottom: 0.5rem;
}
