.modifyModal {
   /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}
.datasContainer input {
  margin-top: 0.4rem;
}

.datasContainer > * {
  margin-top: 1.5rem;
  padding: 0;
}

.nameContainer {
  padding: 0;
}

.nameContainer h5 {
  margin-bottom: 0.5rem;
}

.filtersContainer {
  padding: 0 1rem;
}

.categoryButton {
  padding: 0.7rem;
  margin-right: 0.5rem;
}

.noItemsAlert {
  margin: 0.7rem 0.5rem;
}

.hide {
  display: none !important;
}

/* Tabs styles */
.tabs {
  margin-top: 1rem;
  display: flex;
}

.tabs > * {
  width: 25%;
  font-size: 1.15rem;
  padding: 0.9rem 1.3rem;
}

.disabled {
  opacity: 0.5;
}

/* Preview styles */

#searchresultsarea {
  margin-top: 4rem;
  margin-left: 150px;
  font-family: "Arial";
}

#searchresultsnumber {
  font-size: 0.8rem;
  color: gray;
}

.searchresult {
  margin-top: 2rem;
  margin-bottom: 6rem;
  margin-left: 8px;
}

.searchresult h2 {
  font-size: 20px;
  line-height: 1.3;
  font-family: arial, sans-serif;
  color: #1a0dab !important;
  margin-bottom: 3px;
  margin-top: 8px;
}
.searchresult h2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.searchresult a {
  font-size: 14px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
}

.searchresult button {
  font-size: 10px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}
.searchresult p {
  width: 625px;
  font-size: 14px;
  margin-top: 5px;
  color: #4d5156;
  font-family: arial, sans-serif;
}

.bigModal > * {
  max-width: 80rem !important;
}

/* styles for btton regenerate */
.centeredV {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.centeredButton {
  margin-bottom: 0.5rem !important;
}
/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}
