.modifyModal {
   /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}
.colorPicker {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
}

.colorPicker > * {
  cursor: pointer;
}

.colorPicker div {
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
}

.colorPicker span {
  margin-left: 1rem;
}

.prestashopId {
  display: block;
  margin-bottom: 1.1rem;
}
