.errorMessage {
  color: var(--red);
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  display: block;
}

.sectionTitle {
  margin-top: 1rem;
}