.modifyModal {
  /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}
.saveButtonContainer {
  margin-top: -2.9rem;
  margin-right: -0.5rem;
}

.goBackButton {
  margin-top: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.goBackButton svg {
  margin-right: 0.5rem;
}

.headerCol {
  margin-bottom: 1rem;
}

.psId {
  display: block;
  margin-bottom: 1rem;
}
