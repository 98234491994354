.container {
  padding: 1.5rem 2.8rem !important;
}

.container > *:first-child {
  margin-top: 0;
}

@media screen and (max-width: 50em) {
  .container {
    padding: 1rem .5rem !important;
  }
}

@media screen and (max-width: 30em) {
  .container {
    padding: 0 !important;
  }
}
