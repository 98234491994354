.results {
  margin-top: 2rem;
}

.results > * {
  margin-bottom: 2rem !important;
}

/* .searchForm > * > * {
  padding: 0 0.5rem;
} */

.searchOptions {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.searchOptions > * {
  margin: 0.3rem 0;
}

.searchOptions select {
  /* margin-right: 1rem; */
}

.searchOptions button {
  /* margin-left: 1rem;
  margin-right: 1rem; */
}

.hide {
  display: none !important;
}

/* @media only screen and (max-width: 767px) {
  .searchOptions {
    margin-top: 1rem;
  }
} */

.listContainer {
  margin-bottom: .5rem;
}

@media only screen and (max-width: 65em) {
  .searchOptions > * {
    width: 100%;
    margin: 1rem 0;
  }
}
