.filesList {
  list-style: none;
  padding-left: 0;
  margin-top: 0.7rem;
  border-top: 0.1rem solid #dee2e6;
  border-bottom: 0.1rem solid #dee2e6;

  &.disabled {
    opacity: 0.5;

    li:hover {
      background-color: white;
    }
  }

  li {
    padding: 0.9rem 0.9rem;
    border-bottom: 0.1rem solid #dee2e6;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .chckedIcon {
      color: var(--success);
    }

    &:hover {
      background-color: #e9e9e9;
    }

    &:last-child {
      border-bottom: none;
    }

    & > button:nth-child(1) {
      display: none;
    }

    & > div:nth-child(2) {
      flex: 0 0 5%;
    }

    & > div:nth-child(3) {
      flex: 0 0 5%;
      text-align: center;
    }

    & > div:nth-child(4) {
      flex: 0 0 15%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.2rem;
    }

    & > div:nth-child(5) {
      flex: 0 0 45%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.2rem;
    }

    & > div:nth-child(6) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 30%;

      button {
        margin-left: 0.5rem;
      }
    }

    .certificateOptionButton {
      background-color: transparent;
      border: none;

      .pdfIcon {
        height: 1.4rem;
        width: 1.4rem;
      }

      .editIcon {
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    &.selected {
      background-color: #c3e6cb;
    }
  }

  &.selectAvailable li {
    & > button:nth-child(1) {
      display: block;
      flex: 0 0 5%;
      padding: 0.4rem 0;
      background-color: transparent;
      border: none;
      text-align: left;
    }

    & > div:nth-child(6) {
      flex: 0 0 25%;
    }
  }
}

.removeFile {
  background-color: var(--red);
  border-radius: 1rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;

  svg {
    height: 1.4rem;
    width: 1.4rem;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
