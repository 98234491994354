.addButton {
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.disabled {
  opacity: 0.5;
}
