.filesList {
  list-style: none;
  padding-left: 0;
  border-top: 0.1rem solid #dee2e6;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem 0.9rem;
    border-bottom: 0.1rem solid #dee2e6;

    & > div:nth-child(1) {
      flex: 0 0 45%;
    }

    & > div:nth-child(2) {
      flex: 0 0 25%;
    }

    & > div:nth-child(3) {
      flex: 0 0 30%;
      display: flex;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: 0;
        margin-left: .5rem;

        svg {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
}
