.cardItem {
  padding: 1.3rem;
  margin: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
  min-height: 31rem;
  cursor: pointer;
}

.cardItem img {
  border: none;
  margin-bottom: 1.4rem;
  min-height: 18rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.cardItem:hover {
  border: 2px solid var(--success);
}

@keyframes loadingColorWave {
  0%,
  50%,
  100% {
    background-color: rgba(108, 117, 125, 0.438);
  }

  25%,
  75%,
  100% {
    background-color: rgb(255, 255, 255, 1);
  }
}

.photoPlaceholder {
  width: 18rem;
  height: 18rem;
  margin: auto;
  margin-bottom: 1.4rem;
}
