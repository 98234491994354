.table {
  list-style: none;
  padding-left: 0;
}
.categoriesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 0.7rem;
    margin: 0.7rem;
    svg {
      height: 1.4rem;
      width: 1.4rem;
      margin-left: 1.1rem;
    }
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1rem solid #dee2e6;
  background-color: white;
  padding: 1rem 0.7rem;

  & > * {
    display: flex;
    align-items: center;

    // &:nth-child(1) {
    //   flex: 0 0 15%;

    //   svg {
    //     height: 1.2rem;
    //     width: 1.2rem;
    //   }
    // }

    &:nth-child(1) {
      flex: 0 0 10%;
      display: flex;
      align-items: center;
    }

    &:nth-child(2) {
      flex: 0 0 45%;
    }

    &:nth-child(3) {
      flex: 0 0 15%;
    }

    &:nth-child(4) {
      flex: 0 0 15%;
    }

    &:nth-child(5) {
      flex: 0 0 15%;
      display: flex;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: none;
        margin-left: 0.5rem;

        svg {
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }

  &.header {
    font-weight: bold;
    border-top: 0.2rem solid #dee2e6;
    border-bottom: 0.2rem solid #dee2e6;

    // & > *{
    //   justify-content: flex-start;
    // }
  }
}

.syncStatus {
  background-color: transparent;
  border: none;
  padding: 0.3rem 0.5rem;

  svg {
    height: 1.6rem;
    width: 1.6rem;

    &.success {
      color: var(--success);
    }

    &.error {
      color: var(--danger);
    }
  }
}
