.container {
  padding: 2rem;
  border: 0.1rem solid black;
  background-color: rgb(243, 241, 241);
  width: 40%;
  margin: 10rem auto auto auto;
}

.error {
  color: red;
  font-size: 1rem;
}

.inputForm {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

/* RESPONSIVE */
@media only screen and (max-width: 1000px) {
  .container {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
    .container {
      width: 95%;
    }
    .textsizesmall{
        font-size: smaller;
    }
  }