.row > *:nth-child(1),
.header > *:nth-child(1) {
  flex: 0 0 15%;
  padding-left: 1.3rem;
}

.row > *:nth-child(2),
.header > *:nth-child(2) {
  flex: 0 0 20%;
}

.row > *:nth-child(3),
.header > *:nth-child(3) {
  flex: 0 0 40%;
}

.row > *:nth-child(4),
.header > *:nth-child(4) {
  flex: 0 0 20%;
}

@media only screen and (max-width: 65em) {
  .row > *:nth-child(1),
  .header > *:nth-child(1) {
    flex: 0 0 50%;
    padding-left: 0;
  }

  .row > *:nth-child(2),
  .header > *:nth-child(2) {
    flex: 0 0 50%;
    text-align: right;
  }

  .row > *:nth-child(3),
  .header > *:nth-child(3) {
    flex: 0 0 100%;
    text-align: right;
  }

  .row > *:nth-child(4),
  .header > *:nth-child(4) {
    flex: 0 0 100%;
  }

  .row > *:nth-child(4) > * {
    width: 100%;
  }
}
