.textInput {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
}

.textInput input {
  margin-top: 0;
}

.spinner {
  width: 1.28rem;
  height: 1.28rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  color: var(--gray);
}

.inputError input {
  color: var(--danger);
  border-color: var(--danger);
}

.errorMessage {
  display: none;
}

.inputError .errorMessage {
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}
