.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleContainer h1 {
  margin: 1rem 0 1.5rem;
  color: var(--secondary);
}

@media screen and (max-width: 40em) {
  .titleContainer h1 {
    margin-bottom: 1rem;
  }

  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .titleContainer {
    margin-bottom: 2rem;
  }
}