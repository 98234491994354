.uploadVideosForm {
  margin-top: 2.1rem;
}

.videoContainer {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;

  & > * {
    padding: 1rem 1rem 0.8rem 1rem;
    border: 0.1rem solid #c5c5c5;
    border-radius: 0.3rem;
    margin: 0.4rem 0.5rem;
    width: 31%;
    background-color: white;

    iframe {
      width: 100%;
    }

    .optionsContainer {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: transparent;
        border: none;
        padding: 0.5rem;

        svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
