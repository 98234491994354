.title {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.title h1 {
  font-size: 1.5rem;
}

.input {
  text-align: center;

  margin-bottom: 2rem;
  width: 50%;
}

/* Styles for the arrow up */
.arrowContainer {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem;
  opacity: 1;
  z-index: 9999;
  text-align: right;
  cursor: pointer;
}
.arrowUp {
  right: 0%;
  font-size: 3rem;
  color: blueviolet;
  margin-bottom: 2rem;
}
/* Styles for the loading spinner when infinite scroll */
.hideSpinnerDesktop {
  display: none !important;
}

.row p {
  margin: 0;
}

.row > *:nth-child(1),
.header > *:nth-child(1) {
  flex: 0 0 15%;
  padding-left: 0.9rem;
}

.row > *:nth-child(2),
.header > *:nth-child(2) {
  flex: 0 0 15%;
}

.row > *:nth-child(3),
.header > *:nth-child(3) {
  flex: 0 50%;
  padding-right: 1.5rem;
}

.row > *:nth-child(4),
.header > *:nth-child(4) {
  flex: 0 0 10%;
}

.row > *:nth-child(5),
.header > *:nth-child(5) {
  flex: 0 0 10%;
}

@media (max-width: 1040px) {
  .hideSpinnerDesktop {
    display: block !important;
  }
}

@media only screen and (max-width: 40em) {
  .row p {
    text-align: right;
  }

  .row > *:nth-child(1),
  .header > *:nth-child(1) {
    flex: 0 0 30%;
    padding-left: 0;
  }

  .row > *:nth-child(2),
  .header > *:nth-child(2) {
    order: 2;
    flex: 0 0 65%;
  }

  .row > *:nth-child(3),
  .header > *:nth-child(3) {
    order: 1;
    flex: 0 0 70%;
    padding-right: 0;
  }

  .row > *:nth-child(4),
  .header > *:nth-child(4) {
    order: 2;
    flex: 0 0 20%;
  }

  .row > *:nth-child(5),
  .header > *:nth-child(5) {
    order: 2;
    flex: 0 0 10%;
  }
}
