.modifyModal {
  /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}
.optionsButtonContainer > div {
  min-width: 8rem;
}

.optionsButtonContainer button {
  margin-right: 0.5rem;
  padding: 0.3rem;
}

.optionsButtonContainer svg {
  height: 1.7rem;
  width: 1.7rem;
}

.rememberToAddVariant {
  font-style: italic;
}

.dataTitle {
  font-size: 1.15rem;
  margin: 1.6rem 0 0.7rem 0;
}

/* Tabs styles */
.tabs {
  margin-top: 1rem;
  display: flex;
}

.tabs > * {
  width: 20%;
  font-size: 1.15rem;
  padding: 0.9rem 1.3rem;
}
/* Headers styles */
.headers {
  display: flex;
  justify-content: space-between;
}

.modalArticle > * {
  max-width: 80rem !important;
}

.sampleModal {
  position: absolute;
  margin: 0 auto;
  top: 1rem;
  width: 100%;
  max-width: 80rem;
  background-color: white;
}
/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}

.hideAcordionOnPC {
  display: none;
}

.noActiveAlert {
  margin: 0;
}

@media only screen and (max-width: 65em) {
  .hideTabsWhenMobile {
    display: none;
  }

  .hideAcordionOnPC {
    display: block;
  }
}

/* styles error message */
.errorMessage {
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}
