.modifyModal {
  margin-top: 3rem;
}
.modifyBottomModal {
  margin-bottom: 2rem;
}
.modifyBottomModal {
  margin-bottom: 2rem;
}
.spinnerContainer {
  padding: 5rem 0;
}

.searchFormOptions {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
justify-content: flex-end;
}

.duplicatesMessage {
  margin: 1rem 0;
}

.hide {
  display: none !important;
}

@media only screen and (max-width: 65em) {
  .hideOnMobile {
    display: none;
  }
}
