.saveButtonFixed {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem;
  opacity: 1;
  z-index: 20;
  text-align: right;
}

.saveButtonFixed > *:first-child {
  margin-bottom: 0.3rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
}

.processingSync {
  color: white;
  display: flex;
  align-items: center;
}
