.table {
  margin-top: 1.5rem;
}

.optionsButtonsContainer {
  display: flex;
  justify-content: center;
}

.addDuplicateButton {
  margin-right: 1rem;
  color: white !important;
}

@keyframes colorFlash {
  from {
    background-color: default;
    color: black;
  }

  25%,
  75% {
    background-color: #c3e6cb;
    color: var(--success);
  }

  to {
    background-color: default;
    color: black;
  }
}

.rowEnter {
  opacity: 0;
}

.rowEnterActive {
  opacity: 1;
  transition: all 600ms linear;
  animation-duration: 600ms;
  animation-name: colorFlash;
}
