.selectCicle {
  flex: 0 0 5%;
  padding-left: 1rem;
}

.featureName {
  flex: 0 0 30%;
}

.featureName > * {
  margin: 0;
}

.addValueButton {
  flex: 0 0 10%;
  justify-content: flex-end;
}

.addValueButton button {
  margin: 0;
  padding: 0.2rem;
}

.addValuesButton {
  flex: 0 0 5%;
}

.addValuesButton button {
  padding: 0.4rem;
}

.valuesContainer {
  flex: 0 0 49%;
  flex-wrap: wrap;
}

.valuesContainer > * {
  margin: 0;
  margin-right: 1rem;
}

.unlinkButton {
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.attributeLine h5 {
  margin: 0;
}

.removeRow {
  border-color: #ed969e;
}

.attributeOption {
  padding: 0.9rem;
  background-color: transparent;
  border: none;
}

.attributeOption svg {
  height: 1.5rem;
  width: 1.5rem;
}

.spinner span {
  width: 1.2em;
  height: 1.2rem;
}

.header {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.noValuesMessage {
  font-style: italic;
  color: var(--gray);
}

@media only screen and (max-width: 65em) {
  .hideMobile {
    display: none;
  }

  .featureName > * {
    margin: 0;
  }

  .attributeOption {
    padding: 0;
  }

  .valuesContainer {
    margin-top: 0.5rem;
  }

  .valuesContainer > * {
    width: 100%;
  }

  .unlinkButton {
    order: 1;
    flex: 0 0 15%;
  }

  .featureName {
    flex: 0 0 70%;
  }

  .addValuesButton {
    flex: 0 0 15%;
  }

  .valuesContainer {
    order: 2;
    flex: 0 0 100%;
  }

  .valuesContainer > * {
    margin-right: 0;
  }
}
