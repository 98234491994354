.processing {
  opacity: 0.5;
}

.optionsContainer button {
  margin-right: 0.5rem;
  padding: 0.8rem;
  background-color: transparent;
  border: none;
}

.optionsContainer button:disabled {
  background-color: transparent;
}

.optionsContainer svg {
  height: 1.18rem;
  width: 1.18rem;
}

.spinnerContainer {
  padding: 0.86rem 0.8rem;
}

.spinnerContainer span {
  height: 1.18rem;
  width: 1.18rem;
}

.row p {
  margin: 0;
}

.header > *:nth-child(1),
.row > *:nth-child(1) {
  flex: 0 0 10%;
}

.row > *:nth-child(1) {
  padding-left: 1rem;
}

.header > *:nth-child(2),
.row > *:nth-child(2) {
  flex: 0 0 10%;
}

.header > *:nth-child(3),
.row > *:nth-child(3) {
  flex: 0 0 10%;
}

.header > *:nth-child(4),
.row > *:nth-child(4) {
  flex: 0 0 40%;
}

.header > *:nth-child(5),
.row > *:nth-child(5) {
  flex: 0 0 10%;
}

.header > *:nth-child(6),
.row > *:nth-child(6) {
  flex: 0 0 10%;
}

@media only screen and (max-width: 65em) {
  .row {
    padding: 0.9rem;
  }

  .row p {
    text-align: right;
    width: 100%;
    margin: 0;
  }

  .row > *:nth-child(1) {
    padding-left: 0;
    flex: 0 0 20%;
  }

  .row > *:nth-child(2) {
    order: 1;
    flex: 0 0 50%;
  }

  .row > *:nth-child(3) {
    order: 1;
    flex: 0 0 50%;
  }

  .row > *:nth-child(4) {
    order: 0;
    flex: 0 0 80%;
  }

  .row > *:nth-child(5) {
    order: 2;
    flex: 0 0 50%;
  }

  .row > *:nth-child(6) {
    order: 2;
    flex: 0 0 50%;
    justify-content: flex-end;
  }
}
