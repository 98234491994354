.addDuplicateButton svg {
  color: white;
}

.originalCategoryTd {
  width: 13rem;
}

.noAnimation {
  animation: none;
}

.animated {
  animation: important 0.82s ease-in-out;
}

.saveButton {
  display: flex;
  justify-content: right;
justify-content: flex-end;
  padding: 0.1rem 1rem;
}

.saveButton button,
.saveButton span {
  color: white;
}

@keyframes important {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30%,
  70% {
    background-color: #ffc107;
    border-color: #ffc107;
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -2deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -2deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -2deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -2deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
