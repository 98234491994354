.titleContainer {
  margin-top: 1.3rem;
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 40em) {
  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem 0;
  }
}