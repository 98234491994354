.results {
  margin-top: 2rem;
}

.results > * {
  margin-bottom: 2rem !important;
}

.searchForm > * > * {
  padding: 0 .5rem;
}

.searchOptions {
  display: flex;
}

.searchOptions select {
  margin-right: 0.5rem;
}

.hide{
  display: none !important;
}


@media only screen and (max-width: 767px) {
  .searchOptions {
    margin-top: 1rem;
  }
}