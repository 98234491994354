.label {
  margin-bottom: 0;
}
.hide {
  display: none !important;
}
/* styles error message */
.errorMessage {
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}
