.modifyModal {
  /* margin-top: 3rem !important; */
}
.modifyBottomModal {
  margin-bottom: 1rem;
}
.productDataContainer {
  padding: 1.6rem;
}

.problemsTableOptions {
  width: 19rem;
}

.inputCol {
  margin: 0.8rem 0;
  padding: 0 1.5rem 0 0;
  display: flex;
  align-items: center;
}

.inputCol > * {
  width: 100%;
}

.inputCol h5 {
  margin-top: 0rem;
}

/* Tabs styles */
.tabs {
  margin-top: 1rem;
  display: flex;
}

.tabs > * {
  width: 16.6%;
  font-size: 1.15rem;
  padding: 0.9rem 1.3rem;
}

/* Cards styles */
.cards {
  padding: 1rem;
  margin-top: 2rem;
  width: 80%;
  border: 1px solid black;
}
/* Headers styles */
.headers {
  display: flex;
  justify-content: space-around;
}
/* Preview styles */

#searchresultsarea {
  margin-top: 4rem;
  margin-left: 150px;
  font-family: "Arial";
}

#searchresultsnumber {
  font-size: 0.8rem;
  color: gray;
}

.searchresult {
  margin-top: 2rem;
  margin-bottom: 6rem;
  margin-left: 8px;
}

.searchresult h2 {
  font-size: 20px;
  line-height: 1.3;
  font-family: arial, sans-serif;
  color: #1a0dab !important;
  margin-bottom: 3px;
  margin-top: 8px;
}
.searchresult h2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.searchresult a {
  font-size: 14px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
}

.searchresult button {
  font-size: 10px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}
.searchresult p {
  width: 625px;
  font-size: 14px;
  margin-top: 5px;
  color: #4d5156;
  font-family: arial, sans-serif;
}
/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}

/* styles for btton regenerate */
.centeredV {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.centeredButton {
  margin-bottom: 0.5rem !important;
}

.attributesListContainer {
  margin-bottom: 1.5rem;
}

.attributesInTable {
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  font-size: 1.55rem;
}

.informativeAttributesTitle {
  margin-top: 1rem;
}
/* styles error message */
.errorMessage {
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}

.hideAcordionOnPC {
  display: none;
}

@media only screen and (max-width: 65em) {
  .hideTabsWhenMobile {
    display: none;
  }

  .hideAcordionOnPC {
    display: block;
  }
}

/* stylles for hover in cell tabs */
.cursorCellTabs {
  cursor: pointer;
}

.moreItemsButton {
  margin-top: 0.4rem;
  margin-left: 1rem;
}

.moreItemsButton span {
  background-color: var(--info);
}
