.goBack {
  background-color: transparent;
  border: none;
  padding: 0.3rem;
  margin-bottom: 2rem;

  svg {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.errorMessage * {
  display: block !important;
  font-size: 0.95rem !important;
  text-align: right !important;
  margin-top: 1rem !important;
}
