.selectToRemoveCheck {
  cursor: pointer;
}

.selectToRemoveCheck svg {
  height: 1.3rem;
  width: 1.3rem;
}

.remove.selectToRemoveCheck svg {
  color: var(--danger);
}
