.optionsContainer {
  width: 15rem;
}

.optionsContainer button {
  margin-right: 0.5rem;
  padding: 0.8rem;
  background-color: transparent;
  border: none;
}

.optionsContainer svg {
  height: 1.18rem;
  width: 1.18rem;
}

.selectToRemove {
  margin-top: 1.5rem;
}

.spinnerContainer {
  padding: 0.86rem 0.8rem;
}

.spinnerContainer span {
  height: 1.18rem;
  width: 1.18rem;
  opacity: 0.5;
}

.disabled {
  opacity: 0.6;
}

.syncSpinner {
  padding: 0.3rem 1rem;
  border: none;
  background-color: transparent;
}

.syncStatus {
  background-color: transparent;
  border: none;
  padding: 0.3rem 0.5rem;
}

.syncStatus svg {
  height: 1.6rem;
  width: 1.6rem;
}

.syncStatus svg.success {
  color: var(--success);
}

.syncStatus svg.error {
  color: var(--danger);
}

/* .table, .table thead, .table tbody {
  display: block;
  width: 100%;
}

.table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
} */

.header {
  border-top: 0.2rem solid #dee2e6;
  border-bottom: 0.2rem solid #dee2e6;
  display: flex;
  padding: 1rem 0;
  font-weight: bold;
}

.tableRow {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1rem solid #dee2e6;
  background-color: white;
}

.tableRow p {
  margin: 0;
}

.tableRow > * {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

.tableRow > *:nth-child(1),
.header > *:nth-child(1) {
  flex: 0 0 15%;
}

.tableRow > *:nth-child(1) {
  padding-left: 1rem;
}

.tableRow > *:nth-child(2),
.header > *:nth-child(2) {
  flex: 0 0 10%;
}

.tableRow > *:nth-child(3),
.header > *:nth-child(3) {
  flex: 0 0 30%;
}

.tableRow > *:nth-child(4),
.header > *:nth-child(4) {
  flex: 0 0 10%;
}

.tableRow > *:nth-child(5),
.header > *:nth-child(5) {
  flex: 0 0 15%;
}

.tableRow > *:nth-child(6),
.header > *:nth-child(6) {
  flex: 0 0 15%;
  display: flex;
}

@media only screen and (max-width: 50em) {
  .tableRow p {
    text-align: right;
  }

  .tableRow {
    padding: 0.9rem;
  }

  .tableRow > *:nth-child(1) {
    flex: 0 0 30%;
    padding-left: 0;
  }

  .tableRow > *:nth-child(3) {
    flex: 0 0 70%;
    display: flex;
    justify-content: right;
justify-content: flex-end;
  }

  .tableRow > *:nth-child(4) {
    flex: 0 0 25%;
  }

  .tableRow > *:nth-child(5) {
    flex: 0 0 25%;
  }

  .tableRow > *:nth-child(6) {
    flex: 0 0 50%;
    display: flex;
    justify-content: right;
justify-content: flex-end;
  }

  .tableRow > *:nth-child(6) > * {
    margin-right: 0;
    margin-left: 0.3rem;
  }
}

@media only screen and (max-width: 30em) {
  .tableRow > *:nth-child(4) {
    flex: 0 0 50%;
  }

  .tableRow > *:nth-child(5) {
    flex: 0 0 50%;
    justify-content: flex-end;
  }

  .tableRow > *:nth-child(6) {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
  }

  .tableRow > *:nth-child(6) > * {
    flex: 0 0 30%;
    margin-left: 0;
  }
}
