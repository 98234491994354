.draggableItem {
  background-color: #ffffff;
}

.disabled.draggableItem {
  opacity: 0.5;
}

.unselect {
  display: none;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.unselect svg {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--danger);
}

.accordionButton:hover {
  display: flex;
  justify-content: space-between;
}

.accordionButton:hover .unselect {
  display: block;
}

.accordionButton:hover::after {
  display: none;
}

.infoType {
  color: var(--gray);
}
