

.pagination{
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.hide{
    display: none !important;
}
/* .paddingTop{
  padding-top: 20px;
} */
/* Styles for mobile */
@media only screen and (max-width: 65em) {
    .hideOnMobile {
      display: none;
    }
  }