.productDataRow > * {
  margin: 0.8rem 0;
}
.hide {
  display: none !important;
}
/* styles error message */
.errorMessage {
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}
.marginTop {
  margin-top: 2rem;
}
/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}

.secondErrorMessage {
  display: block !important;
  font-size: 0.9rem !important;
  margin-top: 0;
  text-align: right;
}
.none{
  display: none;
}