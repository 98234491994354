body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Sweet alert styles */
.customSweat {
  width: 100%;
  max-width: 50rem;
}

.customSweat button {
  min-width: 5rem;
}

.greySweetButton {
  background-color: #efefef;
  color: #555;
}

.greySweetButton:not([disabled]):hover {
  background-color: #e8e8e8;
}

.bigModal > * {
  max-width: 80rem !important;
  padding: 0 1rem;
}

.mediumModal > * {
  max-width: 60rem !important;
  padding: 0 1rem;
}


.activeFilters > * {
  position: relative;
}

.activeFilters .accordion-body {
  position: absolute;
  z-index: 9999;
  background-color: white;
  border: 0.1rem solid lightgrey;
  width: 100%;
}

.hideOnPC {
  background-color: salmon !important;
  padding: 4rem !important;
  display: none !important;
}

@media only screen and (max-width: 65em) {
  .hideOnPC {
    display: block !important;
  }

  .hideOnMobile {
    display: none !important;
  }

  .customSweat {
    border-radius: 0;
  }
}
