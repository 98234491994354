.titlesContainer {
  padding: 2rem;
  text-align: center;
}

.container {
  margin-top: 0.5rem;
}

.activatedFilters {
  display: flex;
}

.activatedFilters > * {
  margin-right: 1rem;
  min-width: 20rem;
}

.titlePrice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titlePrice button {
  padding: 0.5rem 1rem;
}

.titlePrice svg {
  height: 1.3rem;
  width: 1.3rem;
}

.productLink,
.productLink:hover {
  text-decoration: none;
  color: black;
}

.noProducts {
  margin: 0.8rem 1.79rem;
}

.filterOptionsContainer {
  font-size: 1rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-wrap: nowrap !important;
  overflow-y: scroll !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filterOptionsContainer::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filterOptionsContainer {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.horizontalOptionsContainer {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 1500px) {
  .productCol {
    width: 33%;
  }

  .productCol {
    padding: 0.5rem !important;
  }

  .productCol a > div {
    margin: 0.4rem;
  }
}

.menuButton {
  display: none;
  background-color: transparent;
  padding: 0.7rem;
  margin: 2rem;
  border: none;
}

.menuButton svg {
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 1240px) {
  .productCol {
    width: 50% !important;
  }
}

@media screen and (max-width: 800px) {
  .productCol {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .menu {
    transform: translateX(-100%);
    position: absolute;
    z-index: 9999;
    background-color: white;
    transition: all 0.59s ease-in-out;
  }

  .showMenu {
    transform: translateX(0);
  }

  .container > * > * {
    width: 100% !important;
  }
}
