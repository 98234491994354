.createProductProcess {
  margin-top: 1.5rem;
}

.actionButtonsErrorMessage {
  text-align: right;
}

.actionButtonsErrorMessage > * {
  padding: 0.3rem;
}

.actionButtons {
  margin: 0 0.3rem;
  display: flex;
  justify-content: right;
justify-content: flex-end;
}

.topSaveOptions > * {
  margin: 0 0.3rem;
}

.saveButtonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
}

.saveButtonsContainer > * {
  margin: 0 0 0 0.4rem;
}

/* .actionButtons > * {
  padding: .5rem .8rem;
} */

.actionButtons span {
  font-size: 1.2rem;
}

.tabs {
  margin-top: 1rem;
  display: flex;
}

.tabs > * {
  width: 30%;
  font-size: 1.15rem;
  padding: 0.9rem 1.3rem;
}

.dataContainer {
  padding-top: 1.4rem;
}

.reverseButton > div > div {
  flex-direction: row-reverse;
}

.reverseButton > div > div > div {
  margin: 0 !important;
}

.reverseButton > div > div > span {
  margin-left: 1.3rem;
}

/* Preview styles */

#searchresultsarea {
  margin-top: 4rem;
  margin-left: 150px;
  font-family: "Arial";
}

#searchresultsnumber {
  font-size: 0.8rem;
  color: gray;
}

.searchresult {
  margin-top: 2rem;
  margin-bottom: 6rem;
  margin-left: 8px;
}

.searchresult h2 {
  font-size: 20px;
  line-height: 1.3;
  font-family: arial, sans-serif;
  color: #1a0dab !important;
  margin-bottom: 3px;
  margin-top: 8px;
}
.searchresult h2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.searchresult a {
  font-size: 14px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
}

.searchresult button {
  font-size: 10px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}
.searchresult p {
  width: 625px;
  font-size: 14px;
  margin-top: 5px;
  color: #4d5156;
  font-family: arial, sans-serif;
}

.topSaveOptions {
  position: absolute;
  right: 0;
}

/* styles for btton regenerate */
.centeredV {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.centeredButton {
  margin-bottom: 0.5rem !important;
}
/* styles for gaping the top of the page */
.mainTitleMargin {
  margin-top: 4rem !important;
}

/*TODO: X Move to global css*/
.hideOnPC {
  display: none !important;
}

.errorInfoMessage {
  font-style: italic;
  color: var(--gray);
  max-width: 30rem;
}

@media only screen and (max-width: 65em) {
  .mainTitleMargin {
    margin-top: 0 !important;
  }

  .hideOnPC {
    display: block !important;
  }

  .actionButtonContainer {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}
