.idHeader {
  width: 8rem;
}

.optionsHeader {
  width: 19rem;
}

.problemItemContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.cursorBehavior {
  cursor: pointer;
}
/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}

.chevronIcon {
  margin-left: 1.4rem;
}

.problemCountBadge {
  margin-left: 1rem;
}

.header > *:nth-child(1),
.row > *:nth-child(1) {
  flex: 0 0 10%;
}

.header > *:nth-child(2),
.row > *:nth-child(2) {
  /* !!dario cambio de 35% */
  flex: 0 0 25%;
}

.header > *:nth-child(3),
.row > *:nth-child(3) {
  /* !!dario cambio de 35% */
  flex: 0 0 20%;
}

.header > *:nth-child(4),
.row > *:nth-child(4) {
  flex: 0 0 20%;
}

@media only screen and (max-width: 65em) {
  .header > *:nth-child(1),
  .row > *:nth-child(1) {
    flex: 0 0 30%;
  }

  .header > *:nth-child(2),
  .row > *:nth-child(2) {
    flex: 0 0 70%;
  }

  .header > *:nth-child(3),
  .row > *:nth-child(3) {
    flex: 0 0 30%;
  }

  .header > *:nth-child(4),
  .row > *:nth-child(4) {
    flex: 0 0 70%;
  }
}

@media only screen and (max-width: 30em) {
  .row > *:nth-child(1),
  .row > *:nth-child(2),
  .row > *:nth-child(3),
  .row > *:nth-child(4) {
    flex: 0 0 100%;
  }
}
