.articlesContainer {
  margin-bottom: .7rem;

  button {
    margin-right: 0.5rem;

    span {
      margin-right: 0.5rem;
    }
  }
}
