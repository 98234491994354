.title {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.title h1 {
  font-size: 1.5rem;
}

.input {
  text-align: center;

  margin-bottom: 2rem;
  width: 50%;
}

.firstTitle {
  display: flex;
  justify-content: center;
}
.firstTitle h1 {
  margin-left: 3rem;
} /* Styles for the arrow up */
.arrowContainer {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1rem;
  opacity: 1;
  z-index: 9999;
  text-align: right;
  cursor: pointer;
}
.arrowUp {
  right: 0%;
  font-size: 3rem;
  color: blueviolet;
  margin-bottom: 2rem;
}
/* Styles for the loading spinner when infinite scroll */
.hideSpinnerDesktop{
  display: none !important;
}
@media (max-width: 1040px) {
  .hideSpinnerDesktop{
    display: block  !important;
  }
}