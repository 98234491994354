.buttonTableOption {
  padding: 0.5rem 0.7rem;
  margin: 0 auto;
  display: block;
}

.buttonTableOption svg {
  height: 1.6rem;
  width: 1.6rem;
}
