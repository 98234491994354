.container {
    padding: 2rem;
    border: 0.1rem solid black;
    background-color: rgb(243, 241, 241);
    width: 60%;
    margin: 10rem auto
}

.error{
    color: red;
    font-size: 1rem;
}

.inputForm{
    margin-bottom: 1.5rem ;
}

@media only screen and (max-width: 767px) {
    .container {
        width: 80%;

    }
  }
