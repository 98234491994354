.sphereContainer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.title {
  margin-left: 1.7rem;
}

.sphere {
  padding: 0.4rem;
  display: block;
  background-color: var(--success);
  border-radius: 10rem;
  border: none;
}

.sphere svg {
  width: 1.54rem;
  height: 1.54rem;
  color: white;
  margin: 0;
  padding: 0;
  display: block;
}

.disabled {
  opacity: .5;
}

.errorMessage {
  margin-top: 0.7rem;
}

.red {
  background-color: var(--danger);
}

.grey {
  background-color: var(--secondary);
}
