.actionButtons {
  display: flex;
  justify-content: right;
justify-content: flex-end;
  flex-wrap: wrap;
}
/* -------------------------------- */
.datasContainer input {
  margin-top: 0.4rem;
}

.datasContainer > * {
  margin: 1.5rem 0;
  padding: 0;
}

.filtersContainer {
  padding: 0 1rem;
}

.categoryButton {
  padding: 0.7rem;
  margin-right: 0.5rem;
}

.noItemsAlert {
  margin: 0.7rem 0.5rem;
}

.hide {
  display: none !important;
}

.availableItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.availableItem::after {
  display: none;
}

.availableItem button {
  background-color: transparent;
  border: none;
  display: block;
  padding: 0;
}

.availableItem svg {
  width: 1.25rem;
  height: 1.25rem;
}
/* Tabs styles */
.tabs {
  margin-top: 1rem;
  display: flex;
}

.tabs > * {
  width: 20%;
  font-size: 1.15rem;
  padding: .9rem 1.3rem;
}

.actionButtons {
  display: flex;
  justify-content: right;
justify-content: flex-end;
}

.reverseButton > div > div {
  flex-direction: row-reverse;
}

.reverseButton > div > div > div {
  margin: 0 !important;
}

.reverseButton > div > div > span {
  margin-left: 1.3rem;
}

.disabled {
  opacity: .5;
  cursor: not-allowed;
}
/* Preview styles */

#searchresultsarea {
  margin-top: 4rem;
  margin-left: 150px;
  font-family: "Arial";
}

#searchresultsnumber {
  font-size: 0.8rem;
  color: gray;
}

.searchresult {
  margin-top: 2rem;
  margin-bottom: 6rem;
  margin-left: 8px;
}

.searchresult h2 {
  font-size: 20px;
  line-height: 1.3;
  font-family: arial, sans-serif;
  color: #1a0dab !important;
  margin-bottom: 3px;
  margin-top: 8px;
  
}
.searchresult h2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.searchresult a {
  font-size: 14px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
}

.searchresult button {
  font-size: 10px;
  line-height: 14px;
  color: green;
  margin-bottom: 0px;
  padding: 0px;
  border-width: 0px;
  background-color: white;
}
.searchresult p {
  width: 625px;
  font-size: 14px;
  margin-top: 5px;
  color: #4d5156;
  font-family: arial, sans-serif;
}

.topSaveOptions {
  position: absolute;
  right: 0;
}

.topSaveOptions > * {
  margin-bottom: 0;
}

/* styles for gaping the bottom of the page */
.marginBottom {
  margin-bottom: 6rem;
}
/* styles for gaping the top of the page */
.marginTop{
  margin-top: 4rem !important;
  /* color: red ; */
}
/* styles error message */
.errorMessage {
  margin-top: 1rem;
  color: var(--danger);
  font-style: italic;
  padding-left: 0.2rem;
  display: block;
}

.hideOnPC {
  display: none !important;
}

@media only screen and (max-width: 65em) {
  .mainTitleMargin {
    margin-top: 0 !important;
  }

  .hideOnPC {
    display: block !important;
  }

  .actionButtons {
    position: fixed;
    bottom: .5rem;
    right: .5rem;
  }
}