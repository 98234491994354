.availableItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.availableItem::after {
  display: none;
}

.availableItem button {
  background-color: transparent;
  border: none;
  display: block;
  padding: 0;
}

.availableItem svg {
  width: 1.25rem;
  height: 1.25rem;
}

.type {
  color: var(--gray);
}
