.buttonWithOptions {
  width: auto;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.3rem 0.6rem;
}

.buttonWithOptions span {
  margin: 0.3rem 0;
}

.buttonWithOptions > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonWithOptions .dupliatedCategoryesOptions {
  display: flex;
  align-items: center;
  margin-left: 1.3rem;
}

.buttonWithOptions .dupliatedCategoryesOptions button {
  background-color: transparent;
  border: none;
  padding: 0.1rem;
  padding-right: 0;
  margin-left: 0.5rem;
  color: white;
}

.buttonWithOptions .dupliatedCategoryesOptions button:first-child {
  margin-left: 0;
}

.buttonWithOptions .dupliatedCategoryesOptions span {
  margin-left: 0.5rem;
}

.buttonWithOptions button svg {
  height: 1.4rem;
  width: 1.4rem;
}

.buttonWithOptions button svg.exception {
  height: 1.15rem;
  width: 1.15rem;
}

.smallIcon {
  height: 1rem !important;
  width: 1rem !important;
}

.disabled-primary {
  background-color: #007bffa8 !important;
  border-color: #007bffa8 !important;
}

.disabled-success {
  background-color: #73c586 !important;
  border-color: #73c586 !important;
}

.loadingBar {
  width: 100%;
  max-width: 1.25rem;
}
