.accordeonToggle {
  cursor: pointer;
}

.conflictsContainer {
  margin-top: 1rem;
}

.pseudoTable {
  display: flex;

  & > * {
    flex: 0 0 50%;
    padding: 0.5rem;
  }

  .listTitle {
    display: block;
    margin-bottom: .3rem;
  }

  a {
    color: black;
  }
}

.accordeonHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transition: all 0.3s ease-out;
  }

  &.open svg {
    transform: rotateZ(-90deg);
  }
}
