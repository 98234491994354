.dropHereMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > * {
    display: block;
    font-size: 0.9rem;
  }

  svg {
    height: 3.8rem;
    width: 3.8rem;
  }
}

.dropDownFileContainer {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D6D6D6FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 1rem;
  min-height: 12rem;

  &.disabled {
    opacity: 0.5;
  }
}
