.firstItem {
  font-weight: bold;
  font-size: 1.2rem;
  cursor: default;
}

.itemsContainer > *:first-child {
  border-top: 0;
}
.itemsContainer :hover {
  cursor: default;
}

.listGroup > * {
  cursor: pointer;
}

.item:hover {
  cursor: pointer !important;
}
/* .edit:hover{
   cursor: pointer;
 } */

.hide {
  display: none !important;
}

.disabled {
  opacity: 0.5;
}

.accordeonHeaderInfo {
  display: flex;
  align-items: center;
}

.accordeonHeaderInfo button {
  margin-bottom: 0 !important;
  margin-left: 0.5rem;
}

.accordeonHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 1rem;
  cursor: pointer;
}

.chevronIcon {
  margin-right: 0.5rem;
  display: block;
  transition: all 0.3s ease-out;
}

.open {
  transform: rotateZ(90deg);
}
