/* TOP NAVBAR STYLES */
.topNavbar {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid lightgray;
  width: 100%;
  position: fixed;
  top: 0;
}

.logoAndOptions {
  width: 100%;
  max-width: 11rem;
  margin: 0rem 0;
  /* margin-bottom: 0.4rem; */
  top: 0rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.6rem 0.5rem; */
}

.logoAndOptions img {
  width: 100%;
  max-width: 11rem;
}

.logoAndOptions button {
  padding: 0.7rem;
  background-color: transparent;
  border: none;
  display: none;
}

.logoAndOptions button svg {
  width: 1.8rem;
  height: 1.8rem;
}

@media screen and (max-width: 50em) {
  .iconxContainer {
    display: none;
  }
  .positionLeft {
    margin-left: -100%;
  }
  .logoAndOptions {
    width: 100%;
    max-width: none;
  }

  .logoAndOptions button {
    display: block;
  }
}

/* //* CHILDREN STYLES */
.scrollGeneral {
  margin-left: 80px;
  margin-top: 3rem;
  padding: 0;
}

/* //* VERTICAL NAVBAR STYLES */
.containerZeroPadding {
  padding: 0 !important; /* //? !important for override bootstrap spaces */
}
.lateralNavbarContainer {
  display: flex;
  flex-direction: row;
  /* test for independent scroll  */
  height: 100vh;
  position: fixed;
  flex-wrap: wrap; /* //*poniendo wrap en vez de nowrap la altura del background coincide con el contenido, de lo contrario se corta */
  overflow-y: scroll;
  z-index: 20;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.lateralNavbarContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.lateralNavbarContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* //*ICONS VERTICALNAVBAR STYLES*/
.iconsLateralNavbar {
  width: 55px;
  background-color: black;
  margin-top: 48px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 30;
}
.iconContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.iconContainer:hover svg {
  color: rgb(57, 193, 99);
}
.iconsLateralNavbar {
  color: White;
}
.iconsLateralNavbar svg {
  width: 25px;
  height: 25px;
}

/* //* LINKS VERTICALNAVBAR STYLES*/
.linksLateralNavbar {
  width: 250px;
  /* background-color: green; */
  margin-top: 48px;
  height: fit-content;
  z-index: 19;
}

/* Links style */
.link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
/* link title active */
.linkTitleActive {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  background: linear-gradient(
    180deg,
    rgb(247 247 247) 0%,
    rgb(251 251 251) 99%
  );
  padding: 1rem 1rem 1rem 2rem;
  border-top: lightgray solid 1px;
}
.linkTitleActive p {
  margin-bottom: 0;
  color: rgb(57, 193, 99);
}
.linkTitleActive svg {
  color: rgb(57, 193, 99);
}
.linkTitleActive:hover {
  border-top: lightgray 1px solid;

  background: linear-gradient(
    180deg,
    rgb(247 247 247) 0%,
    rgb(251 251 251) 99%
  );
}
.linkTitleActive:hover p,
.linkTitleActive:hover svg {
  color: rgb(57, 193, 99);
}

/* link title */
.linkTitle {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  background: linear-gradient(
    180deg,
    rgb(247 247 247) 0%,
    rgb(251 251 251) 99%
  );
  padding: 1rem 1rem 1rem 2rem;
  border-top: lightgray 1px solid;
}
.linkTitle p,
.linkTitle svg {
  margin-bottom: 0;
  color: gray;
}

.linkTitle:hover {
  /* background: rgb(57, 193, 99); */
}
.linkTitle:hover p,
.linkTitle:hover svg {
  color: rgb(57, 193, 99);
}
/* links from dropdown open */
.linkDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(95, 192, 126);
  text-decoration: none;
}
.linkDropdown a {
  width: 100%;
  background-color: rgb(95, 192, 126);
  height: 55px;
  color: white;
  border-top: 1px #ffffff61 solid;
  padding: 1rem 1rem 1rem 2rem;
}
.linkDropdown a:hover {
  color: white;
  background-color: #33df67;
  font-weight: bold;
  text-decoration: none;
}
/* link simple style */
.linkSimpleActive {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(57, 193, 99);
  height: 55px;
  color: white;
  padding: 1rem 1rem 1rem 2rem;
  border-top: lightgray 1px solid;
}
.linkSimpleActive:hover {
  background: linear-gradient(
    180deg,
    rgb(247 247 247) 0%,
    rgb(251 251 251) 99%
  );
  color: rgb(57, 193, 99);
}
.linkSimpleActive p,
.linkSimpleActive svg {
  margin-bottom: 0;
}
.linkSimple {
}

/* //* X ICON DROPDOWN STYLES*/
.iconxContainer {
  background-color: brown;
  cursor: pointer;
  z-index: 21;
  position: fixed;
  left: 325px;
  top: 55px;
}
.iconxContainer svg {
  width: 40px;
  height: 40px;
  background-color: white;
}

/* //*TRANSITION STYLES */
.transitionLeftOut {
  margin-left: -250px;
  transition: all 0.5s ease-in-out;
}
.transitionLeftIn {
  margin-left: 0;
  transition: all 0.5s ease-in-out;
}
.transitionDropdownCloseSearch {
  /* margin-top: -250px; */
  /* display: none; */
  /* opacity: 0; */
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.transitionDropdownOpenSearch {
  /* margin-top: 0; */
  /* display: block; */
  overflow: hidden;
  /* opacity: 1; */
  /* ? KEEP IN MIND IF THE CONTENT CHANGES IN NUMBER YOU MUST CHANGE THE HEIGHT (55*NUMBER OF ELEMENTS) */
  height: 440px;
  transition: all 0.5s ease-in-out;
}
.transitionDropdownCloseCreate {
  /* margin-top: -250px; */
  /* display: none; */
  /* opacity: 0; */
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.transitionDropdownOpenCreate {
  /* margin-top: 0; */
  /* display: block; */
  overflow: hidden;
  /* opacity: 1; */
  /* ? KEEP IN MIND IF THE CONTENT CHANGES IN NUMBER YOU MUST CHANGE THE HEIGHT (55*NUMBER OF ELEMENTS) */
  height: 165px;
  transition: all 0.5s ease-in-out;
}

.transitionArrowClose {
  color: white;
  transform: none;
  transition: transform 0.5s ease-in-out;
}
.transitionArrowOpen {
  color: white;
  transform: rotate(90deg);
  transition: transform 0.5s ease-in-out;
}

/* DISPLAY NONE/BLOCK STYLES */
.displayXOut {
  margin-top: -100px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.displayXIn {
  margin-top: 0px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
/* //*COLOURS STYLE */
.green {
  color: rgb(57, 193, 99);
}

/* test with hover from un element to change another */
