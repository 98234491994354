.container  {
    display: flex;
    text-align: center;
}

.container h1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

