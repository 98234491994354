.header {
  border-top: 0.2rem solid #dee2e6;
  border-bottom: 0.2rem solid #dee2e6;
  display: flex;
  padding: 1rem 0;
  font-weight: bold;
}

.row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.1rem solid #dee2e6;
}

.row > * {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
